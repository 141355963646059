.App {
  text-align: center;
}

.mapOptions {
  padding: 3px 4px 5px;
  cursor: pointer;
  background-color: rgb(35, 31, 41);
  border-radius: 20px;
  height: 26px;
  width: 26px;
  background-size: initial;
  background-repeat: no-repeat;
  background-position: center center;
}

#root-label {
  width: 600px !important;
}

.MuiInputBase-root
  .MuiInput-root
  .MuiInput-underline
  .MuiInputBase-formControl
  .MuiInput-formControl {
  width: 600px !important;
}

#containerMap > div:first-child {
  position: relative !important;
  min-height: calc(100vh - 113px) !important;
  max-width: calc(100vw - 56px) !important;
}

#containerMap-detail > div:first-child {
  position: relative !important;
  height: calc(100vh) !important;
  max-width: calc(100vw - 56px) !important;
}

.MuiMenu-list.MuiList-padding {
  max-height: 250px;
}

.MuiAlert-root {
  border-radius: 4px !important;
}
.MuiInputBase-fullWidth {
  height: 40px !important;
}
#mapview-search {
  color: white !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #e6e6e6 !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid #e6e6e6 !important;
}

.MuiAutocomplete-popupIndicator {
  color: #000000 !important;
  margin: 0px 12px 0px 0px;
}
.MuiAutocomplete-clearIndicator {
  color: #000000 !important;
}
.MuiAutocomplete-inputRoot {
  flex-wrap: inherit !important;
}
.MuiAutocomplete-option {
  height: 40px;
}

.MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  height: 48px !important;
}

/* Application Common Styling */

.primaryFocusedBtn {
  background-color: #332d3c !important;
  color: #ffffff !important;
  height: 32px !important;
  border-radius: 100px !important;
  margin-right: 15px !important;
  cursor: pointer !important;
  padding: 10px 24px !important;
  box-shadow: none !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 14px 24px !important;
}
.primaryFocusedBtn:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: none !important;
}

.primaryBtn {
  background: #231f29 !important;
  color: white !important;
  border-radius: 4px !important;
  width: 100% !important;
  justify-content: space-between !important;
  font-weight: 600 !important;
  margin: 32px 0px 0px 0px !important;
  height: 48px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  padding: 0px 0px 0px 24px !important;
}
.primaryBtn:hover {
  background-color: #453d51 !important;
  box-shadow: none !important;
}
.primaryBtnSmll {
  background: #231f29 !important;
  color: white !important;
  border-radius: 4px !important;
  justify-content: space-between !important;
  font-weight: 600 !important;
  margin: 20px 0px 5px 0px !important;
  cursor: pointer !important;
  box-shadow: none !important;
}
.primaryBtnSmll:hover {
  background-color: #4d4d4d !important;
  box-shadow: none !important;
}
.secondaryBtn {
  background: #f2f2f2 !important;
  color: #000000 !important;
  border-radius: 4px !important;
  width: 100% !important;
  justify-content: space-between !important;
  font-weight: 600 !important;
  margin: 8px 0px 0px 0px !important;
  height: 48px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  padding: 0px 0px 0px 24px !important;
}
.secondaryBtn:hover {
  background-color: #e6e6e6 !important;
  box-shadow: none !important;
}
.MuiGrid-align-content-xs-flex-end {
  align-content: center !important;
}

.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  margin-left: 0px !important;
}

.primaryFocusedDarkBtn {
  background: #ffffff !important;
  color: #000000 !important;
  height: 40px !important;
  border-radius: 100px !important;
  margin-right: 15px !important;
  cursor: pointer !important;
}
.primaryFocusedDarkBtn:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.primaryFocusedLiteBtn {
  background: #332d3c !important;
  color: #ffffff !important;
  height: 40px !important;
  border-radius: 100px !important;
  margin-right: 15px !important;
  cursor: pointer !important;
}
.primaryFocusedLiteBtn:hover {
  background-color: #332d3c !important;
  color: #ffffff !important;
}

/*Multi progress bar*/
.multicolor-bar {
  margin: 0px;
  float: left;
  text-align: center;
}
.multicolor-bar .bars .bar {
  float: left;
  height: 10px;
}

.multicolor-bar .bars div.bar:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
