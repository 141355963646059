body {
  margin: 0;
  font-family: 'IBM PLEX SANS', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Color for thumb, track */
  scrollbar-color: #B3B3B3 #F8F8F8 !important;
  scrollbar-width: 8px;
}

code {
  font-family: 'IBM PLEX SANS', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F8F8F8;
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background: #B3B3B3;
  border-radius: 1px;
}

::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #E6E6E6;
}

::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #E6E6E6;
}

/* Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

.MuiAutocomplete-popper ::-webkit-scrollbar-track, .MuiPopover-root ::-webkit-scrollbar-track {
  background: inherit;
}

.MuiAutocomplete-popper ::-webkit-scrollbar-track:horizontal, .MuiPopover-root ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #CCCCCC;
}

.MuiAutocomplete-popper ::-webkit-scrollbar-track:vertical, .MuiPopover-root ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #CCCCCC;
}